import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
  name: "buy-flow",
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      basket: 'basket/basket',
      isAuth: 'auth/isAuthenticated',
    })
  },
  methods: {
    ...mapActions({
      getDiscount: 'basket/CHECK_DISCOUNT',
      getBonuses: 'basket/CHECK_BONUSES',
    })
  },
  created() {

    if (this.isAuth && this.basket.length !== 0) {

      let products = this.basket.map(product => {
        return {
          weight_id: product.select_volume,
          count: product.select_count
        }
      })

      this.getDiscount({products})
      this.getBonuses({products})

    }

  }
}
